/**
 * Index page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { thingosColors } from '../../../shared/src/Theme/theme';
import Carousel from '../components/Carousel';
import Container, { ColorContainer, Column, Row } from '../components/Container';
import { HeaderCarousel } from '../components/HeaderCarousel';
import Card from '../components/ImageCard';
import NewsCard from '../components/NewsCard';
import { Page } from '../components/Page';
import { SideBySide } from '../components/SideBySide';
import { Body, Footnote, HeaderH1, Quote } from '../components/Typography';
import { Wave } from '../components/Wave';
import image2 from '../content/carousel-home-smart-home.jpg';
import image3 from '../content/carousel_home_smart_factory.jpg';
import image4 from '../content/carousel_home_smart_retail.jpg';
import { IndexLayout } from '../layouts';

const IndexPage = () => {
	const { t } = useTranslation('home');

	return (
		<IndexLayout>
			<HeaderCarousel
				image1={image2}
				title1={t('home.carousel.slide1')}
				color1="#69bf30"
				image2={image3}
				title2={t('home.carousel.slide2')}
				color2="#f24a26"
				image3={image4}
				title3={t('home.carousel.slide3')}
				waveColor={thingosColors.background.dark}
			/>
			<Page>
				<ColorContainer
					backgroundColor={thingosColors.background.dark}
					style={{ paddingBottom: '0px' }}
				>
					<Container>
						<SideBySide>
							<Column flex>
								<HeaderH1>{t('home.title')}</HeaderH1>
								<Body>{t('home.text')}</Body>
								<Quote>{t('home.quote')}</Quote>
							</Column>
							<StaticImage
								src="../content/products-hub-hand.png"
								alt="ThingOS Hub"
								objectFit="contain"
							/>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH1 style={{ textAlign: 'center' }}>{t('home.news.title')}</HeaderH1>
						<Carousel>
							<NewsCard
								title={t('home.news.title1')}
								image={
									<StaticImage src="../content/location-hannovermesse.jpg" alt="Hannover Messe" />
								}
							>
								{t('home.news.text1')}
							</NewsCard>
							<NewsCard
								title={t('home.news.title2')}
								image={
									<StaticImage src="../content/location-expo-day.jpg" alt="STARTUP AUTOBAHN" />
								}
							>
								{t('home.news.text2a')}
								&nbsp;<a href="https://youtu.be/oJrxFaCzXT4">{t('home.news.text2b')}</a>.
							</NewsCard>
						</Carousel>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH1 style={{ textAlign: 'center' }}>{t('home.showcase.title')}</HeaderH1>
						<Row style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
							<Card
								title={t('home.showcase.product1.title')}
								image={<StaticImage src="../content/demo-smart-shelf.jpg" alt="Smart shelf" />}
							>
								{t('home.showcase.product1.text')}
							</Card>
							<Card
								title={t('home.showcase.product2.title')}
								image={
									<StaticImage src="../content/env-smart-retail-app.png" alt="Home control app" />
								}
							>
								{t('home.showcase.product2.text')}
							</Card>
							<Card
								title={t('home.showcase.product3.title')}
								image={<StaticImage src="../content/demo-bt-mesh.jpg" alt="Bluetooth Mesh" />}
							>
								{t('home.showcase.product3.text')}
							</Card>
							<Card
								title={t('home.showcase.product4.title')}
								image={
									<StaticImage src="../content/products-smart-cube.jpg" alt="Smart office cube" />
								}
							>
								{t('home.showcase.product4.text')}
							</Card>
						</Row>
						<Footnote style={{ padding: '32px 0' }}>{t('home.footnote')}</Footnote>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
export default IndexPage;
