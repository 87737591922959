import * as React from 'react';
import CarouselComponent from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Carousel: React.FC = props => {
	const { children } = props;

	return (
		<CarouselComponent
			draggable
			infinite
			autoPlay
			autoPlaySpeed={5000}
			responsive={{
				superLargeDesktop: {
					// the naming can be any, depends on you.
					breakpoint: { max: 4000, min: 3000 },
					items: 1,
				},
				desktop: {
					breakpoint: { max: 3000, min: 1024 },
					items: 1,
				},
				tablet: {
					breakpoint: { max: 1024, min: 464 },
					items: 1,
				},
				mobile: {
					breakpoint: { max: 464, min: 0 },
					items: 1,
				},
			}}
		>
			{children}
		</CarouselComponent>
	);
};

export default Carousel;
