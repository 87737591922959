import * as React from 'react';
import styled from 'styled-components';

import { Body, HeaderH2 } from './Typography';

const NewsCardStyled = styled.div({
	position: 'relative',
});

const NewsTextContainer = styled.div({
	'@media screen and (min-width: 600px)': {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
	},
});

const NewsCardHeaderH2 = styled(HeaderH2)({
	color: 'white',
	backgroundColor: 'rgba(25,135,197,0.90)',
	padding: 16,
	margin: 0,
});

const NewsCardBody = styled(Body)({
	color: 'white',
	backgroundColor: 'rgba(100,100,100,0.85)',
	padding: 16,
	margin: '32px 0',
	maxWidth: 'inherit',
});

interface NewsCardProps {
	image: React.ReactNode;
	title: string;
}

const NewsCard: React.FC<NewsCardProps> = props => {
	const { title, image, children } = props;

	return (
		<NewsCardStyled>
			{image}
			<NewsTextContainer>
				<NewsCardHeaderH2>{title}</NewsCardHeaderH2>
				<NewsCardBody>{children}</NewsCardBody>
			</NewsTextContainer>
		</NewsCardStyled>
	);
};

export default NewsCard;
