import * as React from 'react';
import styled from 'styled-components';

import { Body, HeaderH2 } from './Typography';

const CardStyled = styled.div({
	maxWidth: 430,
});

interface CardProps {
	image: React.ReactNode;
	title: string;
}

const Card: React.FC<CardProps> = props => {
	const { title, image, children } = props;

	return (
		<CardStyled>
			{image}
			<HeaderH2>{title}</HeaderH2>
			<Body>{children}</Body>
		</CardStyled>
	);
};

export default Card;
