import * as React from 'react';
import styled from 'styled-components';

import { thingosColors } from '../../../shared/src/Theme/theme';
import Carousel from './Carousel';
import { HeaderH1 } from './Typography';
import { Wave } from './Wave';

const CarouselLayout = styled.div<{ image?: string }>(({ image }) => ({
	background: `url(${image})`,
	height: '30rem',
	width: '100%',
	position: 'relative',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center center',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));

interface CarouselPageProps {
	image: string;
	title?: string;
	color?: string;
}

export const CarouselPage: React.FC<CarouselPageProps> = props => {
	const { image, title, color } = props;

	return (
		<CarouselLayout image={image}>
			{title != null && (
				<div
					style={{
						position: 'absolute',
						backgroundColor: 'white',
						borderRadius: '4px',
					}}
				>
					<HeaderH1
						style={{
							margin: '10px',
							marginLeft: '20px',
							marginRight: '20px',
							color: color ? color : thingosColors.text.primary,
						}}
					>
						{title}
					</HeaderH1>
				</div>
			)}
		</CarouselLayout>
	);
};
interface HeaderCarouselProps {
	image1: string;
	image2: string;
	image3?: string;
	image4?: string;
	title1?: string;
	title2?: string;
	title3?: string;
	title4?: string;
	color1?: string;
	color2?: string;
	color3?: string;
	color4?: string;
	waveColor: string;
}

export const HeaderCarousel: React.FC<HeaderCarouselProps> = props => {
	const {
		image1,
		image2,
		image3,
		image4,
		title1,
		title2,
		title3,
		title4,
		color1,
		color2,
		color3,
		color4,
		waveColor,
	} = props;
	return (
		<div
			style={{
				position: 'relative',
				width: '100%',
				height: '30rem',
			}}
		>
			<div style={{ position: 'absolute', zIndex: '1', width: '100%', height: '30rem' }}>
				<Carousel>
					<CarouselPage image={image1} title={title1} color={color1} />
					<CarouselPage image={image2} title={title2} color={color2} />
					{image3 != null && <CarouselPage image={image3} title={title3} color={color3} />}
					{image4 != null && <CarouselPage image={image4} title={title4} color={color4} />}
				</Carousel>
			</div>
			<div
				style={{
					position: 'absolute',
					zIndex: '2',
					width: '100%',
					bottom: '0px',
				}}
			>
				<Wave color={waveColor} />
			</div>
		</div>
	);
};
